import type { Item } from '@vinted/web-ui/dist/types/lib/Tabs/types'

import { USER_AGENT_DEVICE } from 'constants/breakpoint'

type TabItem = Item<string | number>

export type { TabItem }

export type InputStyle = 'narrow' | 'wide' | 'tight'
export type InputTheme = 'primary'
export type InputType = 'text' | 'password' | 'number' | 'email' | 'date'
export enum InputMode {
  None = 'none',
  Decimal = 'decimal',
  Numeric = 'numeric',
  Telephone = 'tel',
  Search = 'search',
  Email = 'email',
  Url = 'url',
}

export enum NotificationPosition {
  Bottom,
  Top,
  TopRight,
  Parent,
}

export type BreakpointMap = {
  phones?: boolean
  portables?: boolean
  tablets?: boolean
  tabletsUp?: boolean
  desktops?: boolean
  wide?: boolean
}

export type BreakpointList = Array<keyof BreakpointMap>

export type Breakpoints = BreakpointMap & {
  active: BreakpointList
}

export type GridItem<T> = {
  id: number | string
  type?: string
  data: T
}

export type ItemVisibleCallbackArgs<T> = {
  item: GridItem<T>
  index: number
}

export type PerformanceChartDataPoint = {
  value_1?: number
  value_2?: number | null
  label: string
}

export type InputDropdownValue = string | null

export type InputDropdownRenderState = {
  inputValue: InputDropdownValue
  isOpen: boolean
  isSaveEnabled: boolean
}

export type InputDropdownRenderProps = InputDropdownRenderState & {
  setIsSaveEnabled: (isSaveEnabled: boolean) => void
  closeDropdown: () => void
}

export type InputDropdownSelectType = {
  id: number | string
  title: string | null | undefined
  body?: string | null
  isDisabled?: boolean
}

type ListItemId = number | string
export type SelectableListItem<T, V = string | number> = {
  id: ListItemId
  title: string | JSX.Element
  value: V
  data?: T
}

// TODO: Extracted from intl-messageformat package, move this somewhere else.
export type PrimitiveType = string | number | boolean | null | undefined | Date
export type FormattedMessageValues = Record<string, PrimitiveType | JSX.Element>

export type GooglePlacesServiceRenderProps = {
  autocompleteService: google.maps.places.AutocompleteService | null
  autocompleteSessionToken: google.maps.places.AutocompleteSessionToken | null
}

export type Device = keyof typeof USER_AGENT_DEVICE
