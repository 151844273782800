import { FaqEntryDto } from 'types/dtos'
import { FaqEntryModel } from 'types/models'

export const transformFaqEntryDto = (faqEntryDto: FaqEntryDto): FaqEntryModel => {
  const {
    id,
    title,
    body,
    url,
    transaction_id_visible,
    transaction_id_required,
    member_id_visible,
    member_id_required,
    item_id_required,
    attachments_visible,
    attachments_required,
    submit_photo_tips,
    submit_button,
    english_allowed_visible,
    question,
    proceed_button,
    contact_allowed,
    children,
    code,
    delays_ticket,
    proof_gathering_allowed,
  } = faqEntryDto

  return {
    id,
    body,
    title: title || '',
    url,
    transactionIdVisible: transaction_id_visible,
    transactionIdRequired: transaction_id_required,
    memberIdVisible: member_id_visible,
    memberIdRequired: member_id_required,
    itemIdRequired: item_id_required,
    attachmentsVisible: attachments_visible,
    attachmentsRequired: attachments_required,
    submitPhotoTips: submit_photo_tips,
    submitButton: submit_button,
    englishAllowedVisible: english_allowed_visible,
    isQuestion: question,
    proceedButton: proceed_button || undefined,
    isContactAllowed: contact_allowed,
    children,
    code,
    isComplaintsFaq: delays_ticket,
    proofGatheringAllowed: proof_gathering_allowed,
  }
}
